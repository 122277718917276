export default [
    // {
    //   path: '/admin-dashboard',
    //   name: 'Admin Dashboard',
    //   component: () => import('@/views/admin/admin-home/AdminHome.vue'),
    //   meta: {
    //     resource: 'admin',
    //     action: 'read',
    //   },
    // },
    {
      path: '/company',
      name: 'Company',
      component: () => import('@/views/admin/company/CompanyHome.vue'),
      meta: {
        resource: 'parent',
        action: 'read',
      },
    },
    // {
    //   path: '/company-add',
    //   name: 'Company Add',
    //   component: () => import('@/views/admin/company/CompanyAdd.vue'),
    //   meta: {
    //     resource: 'parent',
    //     action: 'read',
    //   },
    // },
    {
      path: '/company-edit',
      name: 'Company Edit',
      component: () => import('@/views/admin/company/CompanyEdit.vue'),
      props: true,
      meta: {
        resource: 'parent',
        action: 'read',
      },
    },
    {
      path: '/questionnaire',
      name: 'Questionnaire',
      component: () => import('@/views/admin/questionnaire/QuestionnaireHome.vue'),
      meta: {
        resource: 'admin',
        action: 'read',
      },
    },
    {
      path: '/questionnaire-create',
      name: 'Questionnaire Create',
      component: () => import('@/views/admin/questionnaire/QuestionnaireCreate.vue'),
      meta: {
        resource: 'admin',
        action: 'read',
      },
    },
    {
      path: '/questionnaire-edit',
      name: 'Questionnaire Edit',
      component: () => import('@/views/admin/questionnaire/QuestionnaireEdit.vue'),
      meta: {
        resource: 'admin',
        action: 'read',
      },
    },
    {
      path: '/questionnaire-view',
      name: 'Questionnaire View',
      component: () => import('@/views/admin/questionnaire/QuestionnaireView.vue'),
      meta: {
        resource: 'admin',
        action: 'read',
      },
    }
  ]