import AxiosService from '../../Service/AxiosService';
import { BToast } from 'bootstrap-vue'
import { app } from "@/main";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '../../router'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ability from '../../libs/acl/ability'

export default {
    state: {
        token:null,
        user:null,
        isLoading:false
    },
    getters: {
        getToken(state){
            return state.token
        },
        getUser(state){
            return state.user;
        },
        getIsLoading(state){
            return state.isLoading
        }
    },
    mutations: {
        setToken(state, token){
            state.token = token
        },
        setUser(state, user){
            state.user = user;
        },
        clearAuth(state){
            state.user=null;
            state.token=null;
        },
        setIsLoading(state, val) {
            state.isLoading = val
        }
    },
    actions: {
        setLoader({ commit }, value) {
            commit('setIsLoading', value);
        },
        getUser({commit, dispatch}){
            // dispatch("setLoading", true);
            AxiosService.get("/user").then(({user, authError, message})=>{
                // dispatch("setLoading", false)
                if(authError || !user){
                    dispatch("logout")
                    app.$bvToast.toast(message, {
                        variant: "danger"
                      });
                    // Toast.open({
                    //     message: message,
                    //     type: 'is-danger'import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

                    // })
                    //logout
                }
                else{
                    localStorage.setItem("userData", JSON.stringify(user))
                    commit("setUser", user)
                    ability.update(user.ability)
                }
            })
        },
    
        login({commit, dispatch}, payload){
            // dispatch("setLoading", true)
            AxiosService.post("/login", payload).then(({token, message})=>{
                // dispatch("setLoading", false)
                if(!token) {
                    // Toast.open({
                    //     message: message,
                    //     type: 'is-danger'
                    // })
                    app.$bvToast.toast(message, {
                        variant: "danger"
                    });
                }
                else {
                    // Toast.open({
                    //     message: 'Logged In',
                    //     type: 'is-success'
                    // })
                    // app.$bvToast.$toast({
                    //     component: ToastificationContent,
                    //     position: 'top-right',
                    //     props: {
                    //       title: `Welcome ${userData.name || userData.username}`,
                    //       icon: 'CoffeeIcon',
                    //       variant: 'success',
                    //       text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    //     },
                    //   })

                    app.$bvToast.toast('Logged In', {
                        variant: "success"
                    });
                    localStorage.setItem("token", token)
                    AxiosService.updateAuthHeaders(token);
                    commit("setToken", token)
                    AxiosService.get("/user").then(({user, authError, message})=>{
                        // dispatch("setLoading", false)
                        if(authError){
                            dispatch("logout")
                            app.$bvToast.toast(message, {
                                variant: "danger"
                              });
                        }
                        else{
                            localStorage.setItem("userData", JSON.stringify(user))
                            commit("setUser", user)
                            // console.log("localstorage before redirect", localStorage.getItem("userData"))
                            ability.update(user.ability)
                            router.replace(getHomeRouteForLoggedInUser(user.role))

                        }
                    })

                }
            })
        },
    
        autoLogin({commit, dispatch}){
            try {
                let token = localStorage.getItem("token");
                if(token){
                    AxiosService.updateAuthHeaders(token)
                    commit("setToken", token)
                    dispatch("getUser");
                }
            } catch(e){
                //opened from inside iframe
            }
            
        },
    
        logout({commit}){
            AxiosService.updateAuthHeaders(null);
            localStorage.removeItem("token");
            localStorage.removeItem("userData")
            commit("clearAuth")
            ability.update([{
                action: 'read',
                subject: 'Auth',
            }])
            app.$bvToast.toast('User Logged out', {
                variant: "danger"
            });
            // Toast.open({
            //     message: "User Logged out",
            //     type: 'is-danger'
            // })
            router.push("/login")
        },
        updateUser({dispatch}, payload){
            dispatch("setLoading", true)
            AxiosService.post("/updateUser", payload).then(({authError, user, error})=>{
                dispatch("setLoading", false)
                if(error){
                    Toast.open({
                        message: error,
                        type: 'is-danger'
                    })
                }
                else{
                    Toast.open({
                        message: "Fields updated successfully",
                        type: 'is-success'
                    })
                    dispatch("getUser");
                } 
            })
        },
        signup({dispatch}, payload){
            dispatch("setLoading", true)
            AxiosService.post("/signup", payload).then(({error, token})=>{
                dispatch("setLoading", false)
                if(error){
                    Toast.open({
                        type:"is-danger",
                        message:"Some error occured"
                    })
                }
                else {
                    Toast.open({
                        message: 'Account created',
                        type: 'is-success'
                    })
    
                    localStorage.setItem("token", token)
                    dispatch("autoLogin")
                    router.push("/")
                }
            })
        }
    },
   
  }