import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

let userData = localStorage.getItem('userData')
let existingAbility = null

if( userData==undefined || 
    userData==null || 
    userData=='undefined'
) 
    existingAbility = null
else 
{
    let parsedUser = JSON.parse(userData)
    existingAbility = parsedUser ? parsedUser.ability : null

}

export default new Ability(existingAbility || initialAbility)
