<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <b-overlay
        :show="isLoading"
        rounded="sm"
      >
      <component :is="layout">
        <router-view />
      </component>
    </b-overlay>
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { BOverlay } from 'bootstrap-vue'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
// const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    LayoutVertical,
    LayoutFull,
    BOverlay,
    ScrollToTop,
  },
  data(){
    return {
      // layout : 'layout-vertical' //dont do this
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  mounted(){
    this.$store.dispatch("autoLogin")
    this.$store.dispatch("setLoader", false)
  },
  computed: {
    isLoading(){
      return this.$store.getters.getIsLoading || false
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch:{
    // isLoading(){
    //   console.log("isLoading", this.isLoading)
    // }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>

<style>
  .main-menu.menu-light .navigation > li.sidebar-group-active > a{
    color: #565360 !important;
  }

  .vs__dropdown-option {
    white-space:normal !important;
  }
  .custom-checkbox.b-custom-control-sm .custom-control-label::before{
    left:0 !important;
  }
  .custom-checkbox.b-custom-control-sm .custom-control-label::after{
    left:0 !important;
  }
  .default-tab-navigation{
      display: none !important; 
      /* remove original tab navigation for questionnaire form */
  }
  .heading {
    color: #545454;
    font-family: 'Helvetica Now', Helvetica, Arial, serif;
  }
  
  /* Portfolio color for score */
  .A{
    color: #0000CC;
  }
  .B{
    color:#008000;
  }
  .C{
    color:#FFD700;
  }
  .D{
    color:#FFA500;
  }
  .F{
    color:#FF0000;
  }
  .Z{
    color:#aaa
  }
  
</style>