import AxiosService from '../../Service/AxiosService';


export default {
    state: {
        companies: {
            rows:[],
            totalRows:0
        },
    },
    getters: {
        getCompany(state){
            return state.companies;
        }
    },
    mutations: {
        setCompany(state, company){
            state.companies = company;
        }
    },
    actions: {
        companies({commit}, payload){
            AxiosService.get("/companies", payload).then(({error, message, data})=>{
              
                if(!data) 
                return;
                let companies = {};
                if(data.rows) companies.rows = data.rows;
                if(data.totalRows) companies.totalRows = data.totalRows;

                commit("setCompany", companies)
            })
        }
    }

}