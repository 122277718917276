export default [
    {
      path: '/company-analytics',
      name: 'Company Analytics',
      component: () => import('@/views/dashboard/analytics/Analytics.vue'),
      meta: {
        resource: 'parent',
        action: 'read',
      },
    },
    {
      path: '/portfolio',
      name: 'Portfolio',
      component: () => import('@/views/dashboard/analytics/ScoreCards.vue'),
      meta: {
        resource: 'parent',
        action: 'read',
      },
    },
    {
      path: '/result',
      name: 'Result',
      component: () => import('@/views/dashboard/analytics/portfolioCompany.vue'),
      meta: {
        resource: 'parent',
        action: 'read',
      }
    },

    {
      path: '/Companies',
      name: 'Companies Home',
      component: () => import('@/views/parent/childCompany/ChildCompanyHome.vue'),
      meta: {
        resource: 'parent',
        action: 'read',
      },
    },
    {
      path: '/company-add',
      name: 'Company Add',
      component: () => import('@/views/parent/childCompany/ChildCompanyAdd.vue'),
      meta: {
        resource: 'parent',
        action: 'read',
      },
    },

    // {
    //   path: '/questionnaire-list',
    //   name: 'Company Questionnaire',
    //   component: () => import('@/views/parent/questionnaire/index.vue'),
    //   meta: {
    //     resource: 'parent',
    //     action: 'read',
    //   },
    // },
    // {
    //   path: '/company-result',
    //   name: 'Company Result',
    //   component: () => import('@/views/parent/childCompany/QuestionnaireResult.vue'),
    //   props: true,
    //   meta: {
    //     resource: 'parent',
    //     action: 'read',
    //   },
    // },

    // {
    //   path: '/test',
    //   name: 'Rose',
    //   component: () => import('@/layouts/components/chart/rose-chart.vue'),
    //   meta: {
    //     resource: 'parent',
    //     action: 'read',
    //   },
    // },
  ]