import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    parentCompanies:[
        {title:"Parent1"},
        {title:"Parent2"},
        {title:"Parent3"},
        {title:"Parent4"},
    ],
    childCompanies:[
        {title:"GoJo"},
        {title:"Waystar Royce"},
        {title:"vaulter"},
        {title:"child4"},
    ],
    averageScore:{
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        legend: {},
        grid: {
            left: '100px',
            right: '30px',
            bottom: '30px',
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: ['GoJo', 'Waystar Royce', 'vaulter'],
            splitLine: { show: false },
        },
        series: [
            {
                name: 'Access Control',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: [4, 4.1, 3],
                barWidth: '30%',
            },
            {
                name: 'Business resilience',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: [3.2, 4, 5],
                barWidth: '30%',
            },
            {
                name: 'Data protection',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: [5, 5, 5],
                barWidth: '30%',
            },
            {
                name: 'Endpoint Security',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: [5, 2.1, 4],
                barWidth: '30%',
            },
            {
                name: 'Governance',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: [1, 4.2, 3],
                barWidth: '30%',
            },
            {
                name: 'Network Security',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: [4.4, 4.8, 5],
                barWidth: '30%',
            },
            {
                name: 'SAAS Security',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: [3.2, 4, 5],
                barWidth: '30%',
                itemStyle: {
                    barBorderRadius: [0, 10, 10, 0],
                },
            }

        ]
    }
}
const parentCompanies =  [
    {
      id: 1,
      name: "Parent 1",
      email: 'parent1@mail.com',
      startDate: '09/23/2016',
      avatar: require('@/assets/images/icons/mozila-firefox.png'),
    },
    {
      id: 2,
      name: "Parent 2",
      email: 'parent2@mail.com',
      startDate: '01/13/2019'
    },
    {
        id: 3,
        name: "Parent 3",
        email: 'parent3@mail.com',
        startDate: '09/12/2013',
        avatar: require('@/assets/images/icons/google-chrome.png'),
    },
    {
      id: 4,
      name: "Parent 4",
      email: 'parent1@mail.com',
      startDate: '09/23/2016',
      avatar: require('@/assets/images/icons/mozila-firefox.png'),
    },
    {
      id: 5,
      name: "Parent 5",
      email: 'parent2@mail.com',
      startDate: '01/13/2019',
      avatar: require('@/assets/images/icons/google-chrome.png'),
    },
    {
      id: 6,
      name: "Parent 6",
      email: 'parent3@mail.com',
      startDate: '09/12/2013',
      avatar: require('@/assets/images/icons/mozila-firefox.png'),
    },
    {
      id: 7,
      name: "Parent 7",
      email: 'parent1@mail.com',
      startDate: '09/23/2016',
      avatar: require('@/assets/images/icons/google-chrome.png'),
    },
    {
      id: 8,
      name: "Parent 8",
      email: 'parent2@mail.com',
      startDate: '01/13/2019',
      avatar: require('@/assets/images/icons/mozila-firefox.png'),
    },
    {
      id: 9,
      name: "Parent 9",
      email: 'parent3@mail.com',
      startDate: '09/12/2013',
      avatar: require('@/assets/images/icons/apple-safari.png'),

    },
    {
      id: 10,
      name: "Parent 10",
      email: 'parent1@mail.com',
      startDate: '09/23/2016',
      avatar: require('@/assets/images/icons/google-chrome.png'),
    },
    {
      id: 11,
      name: "Parent 11",
      email: 'parent2@mail.com',
      startDate: '01/13/2019',
      avatar: require('@/assets/images/icons/apple-safari.png'),
    },
    {
        id: 12,
        name: "Parent 12",
        email: 'parent3@mail.com',
        startDate: '09/12/2013'
    },
    {
        id: 1,
        name: "Parent 1",
        email: 'parent1@mail.com',
        startDate: '09/23/2016',
        avatar: require('@/assets/images/icons/mozila-firefox.png'),

      },
      {
        id: 2,
        name: "Parent 2",
        email: 'parent2@mail.com',
        startDate: '01/13/2019',
        avatar: require('@/assets/images/icons/google-chrome.png'),
      },
      {
          id: 3,
          name: "Parent 3",
          email: 'parent3@mail.com',
          startDate: '09/12/2013',
          avatar: require('@/assets/images/icons/apple-safari.png'),
      },
      {
        id: 4,
        name: "Parent 4",
        email: 'parent1@mail.com',
        startDate: '09/23/2016',
        avatar: require('@/assets/images/icons/mozila-firefox.png'),

      },
      {
        id: 5,
        name: "Parent 5",
        email: 'parent2@mail.com',
        startDate: '01/13/2019',
        avatar: require('@/assets/images/icons/google-chrome.png'),
      },
      {
        id: 6,
        name: "Parent 6",
        email: 'parent3@mail.com',
        startDate: '09/12/2013',
        avatar: require('@/assets/images/icons/apple-safari.png'),
      },
      {
        id: 7,
        name: "Parent 7",
        email: 'parent1@mail.com',
        startDate: '09/23/2016',
        avatar: require('@/assets/images/icons/mozila-firefox.png'),

      },
      {
        id: 8,
        name: "Parent 8",
        email: 'parent2@mail.com',
        startDate: '01/13/2019',
        avatar: require('@/assets/images/icons/apple-safari.png'),
      },
      {
        id: 9,
        name: "Parent 9",
        email: 'parent3@mail.com',
        startDate: '09/12/2013'
      },
      {
        id: 10,
        name: "Parent 10",
        email: 'parent1@mail.com',
        startDate: '09/23/2016',
        avatar: require('@/assets/images/icons/mozila-firefox.png'),

      },
      {
        id: 11,
        name: "Parent 11",
        email: 'parent2@mail.com',
        startDate: '01/13/2019',
        avatar: require('@/assets/images/icons/apple-safari.png'),
      },
      {
          id: 12,
          name: "Parent 12",
          email: 'parent3@mail.com',
          startDate: '09/12/2013',
          avatar: require('@/assets/images/icons/mozila-firefox.png'),

      }
]
const childCompanies = [
   {
    id: 1,
    name: "Child 1",
    email: 'child1@mail.com',
    startDate: '09/23/2016',
    avatar: require('@/assets/images/icons/mozila-firefox.png'),
  },
  {
    id: 2,
    name: "Child 2",
    email: 'child2@mail.com',
    startDate: '01/13/2019'
  },
  {
      id: 3,
      name: "Child 3",
      email: 'child3@mail.com',
      startDate: '09/12/2013',
      avatar: require('@/assets/images/icons/google-chrome.png'),
  }
]

/* eslint-disable global-require */
mock.onGet('/companies/child').reply(() => [200, data])

mock.onGet('/companylist/parents').reply(() => [200, parentCompanies])
mock.onGet('/companylist/children').reply(() => [200, childCompanies])