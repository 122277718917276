export default [
    {
      path: '/company-result',
      name: 'Company Result',
      component: () => import('@/views/child/questionnaire/QuestionnaireResult.vue'),
      meta: {
        resource: 'child',
        action: 'read',
      },
    },
    {
      path: '/questionnaire-fill',
      name: 'Questionnaire Fill',
      component: () => import('@/views/child/questionnaire/QuestionnaireFill.vue'),
      meta: {
        resource: 'child',
        action: 'read',
      },
    },
  ]