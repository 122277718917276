import axios from 'axios'
import config from "../Constants/Config.json"
import router from '@/router';

const endpoint = config.backendUrl;

let headers = {
    Authorization:''
}
const updateAuthHeaders = (token)=>{ // User better way to load this (from localStorage)
    headers.Authorization = `${token}`;
    // console.log("header updated");
}

const post = (url, req)=>{
    // console.log("axios post", url, req)
    return new Promise((resolve, reject)=>{
        axios.post(`${endpoint}${url}`, req, {headers})
        .then( res=>{
            if(res.data.authError) router.replace("/logout")
            resolve(res.data);
        })
        .catch(error=>{
            resolve({ error: 'Invalid Token' })
        })
    })
}

const get = (url, req)=>{
    // console.log("headers", headers);
    return new Promise((resolve, reject)=>{
        axios.get(`${endpoint}${url}`, {headers, params:req})
        .then( res=>{
            if(res.data.authError) router.replace("/logout")
            resolve(res.data);
        })
        .catch(error=>{
            resolve({ error: 'Invalid Token' });
        })
    })
}

const downloadPdf = (url, req) =>{
    return new Promise((resolve, reject)=>{
        let tempHeader = headers;
        tempHeader.Accept = 'application/pdf'
        axios.get(`${endpoint}${url}`, {
            responseType: 'arraybuffer',
            headers:tempHeader,
            params:req
        })
        .then( res=>{
            if(res.data.authError) 
                router.replace("/logout")            
            resolve(res);
        })
        .catch(error=>{
            resolve({ error: 'Invalid Token' });
        })
    })
}

export default {
    post,
    get,
    downloadPdf,
    updateAuthHeaders
}