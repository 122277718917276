import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
import parent from './routes/parent'
import child from './routes/child'
import admin from './routes/admin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'Company Analytics' } },
    ...pages,
    ...admin,
    ...parent,
    ...child,
    {
      path: '/users',
      name: 'Users',
      component: () => import('@/views/common/manageUsers/UsersHome.vue'),
      meta: {
        resource: 'child',
        action: 'read',
      },
    },
    {
      path: '/users-add',
      name: 'Users Add',
      component: () => import('@/views/common/manageUsers/UsersAdd.vue'),
      meta: {
        resource: 'child',
        action: 'read',
      },
    },
    {
      path: '/users-edit',
      name: 'Users Edit',
      component: () => import('@/views/common/manageUsers/UsersEdit.vue'),
      meta: {
        resource: 'child',
        action: 'read',
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('@/views/common/logout/Logout.vue'),
      meta: {
        resource: 'child',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {

  try{
      const isLoggedIn = isUserLoggedIn()

    //  console.log("isLoggedIn", isLoggedIn, to.path)

    if(!isLoggedIn)
      if(to.path=="/login" || to.path=="/password" || to.path=="/forgot-password") next()
      else return next("/login")
    

    if (to.meta.redirectIfLoggedIn && to.path=="/login" && isLoggedIn) {
      const userData = getUserData()
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      // if (!isLoggedIn) return next({ name: 'auth-login' })

      // If logged in => not authorized
      return next({ name: 'error-404' })
    }

    return next()
  }
  catch(error){
    console.log("error occured", error)
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
