import mock from '@/@fake-db/mock'

const data = {
    "name": "Form1",
    "pages": [
      {
        "title": "Detail",
        "questions": [
          {
            "type": "Text",
            "question": "Company Name",
            "answer": null
          },
          {
            "type": "Text",
            "question": "Parent Company/Private Equity Firm",
            "answer": null
          },
          {
            "type": "Dropdown",
            "question": "Industry",
            "options": [
              {
                "text": "Agriculture",
                "value": null
              },
              {
                "text": "Energy",
                "value": null
              },
              {
                "text": "transport",
                "value": null
              },
              {
                "text": "Healthcare",
                "value": null
              }
            ],
            "key": null,
            "answer": null
          },
          {
            "type": "Dropdown",
            "question": "Total Employee count",
            "options": [
              {
                "text": "<10",
                "value": null
              },
              {
                "text": "10-100",
                "value": null
              },
              {
                "text": "100-1000",
                "value": null
              },
              {
                "text": ">1000",
                "value": null
              }
            ],
            "key": null,
            "answer": null
          }
        ],
        "sum": 0
      },
      {
        "title": "Access Control",
        "questions": [
          {
            "type": "MultipleChoice",
            "question": "To what extent do you restrict administrator access rights?",
            "options": [
              {
                "text": "Administrator access rights are not restricted",
                "value": 1
              },
              {
                "text": "Administrator access rights are restricted for some systems",
                "value": 2
              },
              {
                "text": "Administrator access rights are restricted for all systems",
                "value": 3
              },
              {
                "text": "Administrator access rights are restricted for all systems based on the risk of the elevated access",
                "value": 4
              }
            ],
            "key": null,
            "answer": null
          },
          {
            "type": "MultipleChoice",
            "question": "To what extent do you maintain an audit log of changes to the administrator groups?",
            "options": [
              {
                "text": "Audit logging is not implemented",
                "value": 1
              },
              {
                "text": "Audit logging is implemented for some systems",
                "value": 2
              },
              {
                "text": "Audit logging is implemented for all systems",
                "value": 3
              },
              {
                "text": "Audit logging is implemented for all systems and is monitored",
                "value": 4
              }
            ],
            "key": null,
            "answer": null
          },
          {
            "type": "MultipleChoice",
            "question": "Is there a policy that defines the requirements for remote access from external networks to the corporate network that has been approved by management and communicated to employees?",
            "options": [
              {
                "text": "There is no defined policy in place for remote access/remote employees",
                "value": 1
              },
              {
                "text": "There is a defined policy in place that has been approved by management",
                "value": 2
              },
              {
                "text": "Other",
                "value": 3
              }
            ],
            "key": null,
            "answer": null
          },
          {
            "type": "MultipleChoice",
            "question": "Do you utilize endpoint security software which includes anti-virus and malware detection/prevention capabilities?",
            "options": [
              {
                "text": "Endpoint security is not deployed",
                "value": 1
              },
              {
                "text": "Endpoint security is installed on some devices",
                "value": 2
              },
              {
                "text": "Endpoint security is installed on all devices",
                "value": 3
              },
              {
                "text": "Endpoint security is installed on all devices and receives updates regularly",
                "value": 4
              },
              {
                "text": "Endpoint security is installed on all devices, receives updates regularly, and sends logs/alerts to a tool that is monitored 24/7/365",
                "value": 5
              }
            ],
            "key": null,
            "answer": null
          }
        ],
        "sum": 0
      }
    ]
}
const data2 = {
    "name": "Form2",
    "pages": [
      {
        "title": "Detail",
        "questions": [
          {
            "type": "Text",
            "question": "Company Name",
            "answer": null
          },
          {
            "type": "Text",
            "question": "Parent Company/Private Equity Firm",
            "answer": null
          },
          {
            "type": "Dropdown",
            "question": "Industry",
            "options": [
              {
                "text": "Agriculture",
                "value": null
              },
              {
                "text": "Energy",
                "value": null
              },
              {
                "text": "transport",
                "value": null
              },
              {
                "text": "Healthcare",
                "value": null
              }
            ],
            "key": null,
            "answer": null
          },
          {
            "type": "Dropdown",
            "question": "Total Employee count",
            "options": [
              {
                "text": "<10",
                "value": null
              },
              {
                "text": "10-100",
                "value": null
              },
              {
                "text": "100-1000",
                "value": null
              },
              {
                "text": ">1000",
                "value": null
              }
            ],
            "key": null,
            "answer": null
          }
        ],
        "sum": 0
      },
      {
        "title": "Access Control",
        "questions": [
          {
            "type": "MultipleChoice",
            "question": "To what extent do you restrict administrator access rights?",
            "options": [
              {
                "text": "Administrator access rights are not restricted",
                "value": 1
              },
              {
                "text": "Administrator access rights are restricted for some systems",
                "value": 2
              },
              {
                "text": "Administrator access rights are restricted for all systems",
                "value": 3
              },
              {
                "text": "Administrator access rights are restricted for all systems based on the risk of the elevated access",
                "value": 4
              }
            ],
            "key": null,
            "answer": null
          },
          {
            "type": "MultipleChoice",
            "question": "To what extent do you maintain an audit log of changes to the administrator groups?",
            "options": [
              {
                "text": "Audit logging is not implemented",
                "value": 1
              },
              {
                "text": "Audit logging is implemented for some systems",
                "value": 2
              },
              {
                "text": "Audit logging is implemented for all systems",
                "value": 3
              },
              {
                "text": "Audit logging is implemented for all systems and is monitored",
                "value": 4
              }
            ],
            "key": null,
            "answer": null
          },
          {
            "type": "MultipleChoice",
            "question": "Is there a policy that defines the requirements for remote access from external networks to the corporate network that has been approved by management and communicated to employees?",
            "options": [
              {
                "text": "There is no defined policy in place for remote access/remote employees",
                "value": 1
              },
              {
                "text": "There is a defined policy in place that has been approved by management",
                "value": 2
              },
              {
                "text": "Other",
                "value": 3
              }
            ],
            "key": null,
            "answer": null
          },
          {
            "type": "MultipleChoice",
            "question": "Do you utilize endpoint security software which includes anti-virus and malware detection/prevention capabilities?",
            "options": [
              {
                "text": "Endpoint security is not deployed",
                "value": 1
              },
              {
                "text": "Endpoint security is installed on some devices",
                "value": 2
              },
              {
                "text": "Endpoint security is installed on all devices",
                "value": 3
              },
              {
                "text": "Endpoint security is installed on all devices and receives updates regularly",
                "value": 4
              },
              {
                "text": "Endpoint security is installed on all devices, receives updates regularly, and sends logs/alerts to a tool that is monitored 24/7/365",
                "value": 5
              }
            ],
            "key": null,
            "answer": null
          }
        ],
        "sum": 0
      }
    ]
}

mock.onGet('/form').reply(() => [200, data])
mock.onGet('/forms').reply(() => [200, [data, data2]])
